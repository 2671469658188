import React, { useState, useEffect, useMemo } from 'react'
import { uniq } from 'lodash'
import SVG from 'react-inlinesvg'
import {
  useApiGet,
  varClass,
  map,
  isBlank,
  capitalize,
  parseQuery,
  navigateTo,
  articlePath,
  tagColors,
  articleGroupLabels,
  getCategoryKey,
  articleSort,
} from '../shared'
import { Header, Loader } from '../layout'

export function ArticlePage(props) {
  const params = parseQuery(props.location.search)
  const pathname = props.params['*'] || ''
  const [category, slug] = pathname.split('/')

  const data = useApiGet(`/docs/${category}/${slug}`, {}, [category, slug])

  const loaded = data?.articles && Array.isArray(data.articles)
  const article = loaded && data.article
  const articles = loaded && data.articles
  const isTopArticle = article && !article.category && !article.subCategory

  const [articleNotFound, setArticleNotFound] = useState(false)

  useEffect(() => {
    if (data?.articles && Array.isArray(data?.articles) && !data?.article) {
      setArticleNotFound(true)
      if (!params.embed && !params.fullscreen) {
        navigateTo('/')
      }
    }
  }, [data, params.embed, params.fullscreen])

  if (loaded && (articleNotFound || !data?.article)) {
    return (
      <main
        className={varClass({
          'article': true,
          'embed': !!params.embed,
          'fullscreen': !!params.fullscreen,
        })}
      >
        <Header {...props} />
        <div className="page-article">
          <Sidebar articles={articles} />
          <div className="article">
            <ArticleNotFound />
            <Footer articles={articles} />
          </div>
          <div className="content" />
        </div>
      </main>
    )
  }

  if (!loaded) return <Loader />

  return (
    <main
      className={varClass({
        'article': true,
        'embed': !!params.embed,
        'fullscreen': !!params.fullscreen,
      })}
    >
      {loaded && (
        <>
          <Header {...props} />
          <div className="page-article">
            <Sidebar article={article} articles={articles} />
            <div
              className={varClass({
                'article': true,
                'top-article': isTopArticle,
              })}
            >
              <Breadcrumbs article={article} />
              <Article article={article} />
              {isTopArticle && (
                <OtherArticles article={article} articles={articles} />
              )}
              <Footer article={article} articles={articles} />
            </div>
            <TableOfContent article={article} />
          </div>
        </>
      )}
    </main>
  )
}

function Breadcrumbs(props) {
  const { article } = props

  return (
    <div className="breadcrumbs">
      <a href={`/?grp=${article.group}`}>
        {articleGroupLabels[article.group] || capitalize(article.group)}
      </a>
      {article.category && (
        <a href={`/cat/${encodeURIComponent(article.category.toLowerCase())}`}>
          {article.category}
        </a>
      )}
      <span>{article.shortTitle}</span>
    </div>
  )
}

function Article(props) {
  const { article } = props

  return (
    <article>
      <h1>{article.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: article.body }} />
    </article>
  )
}

function OtherArticles(props) {
  const { article: currentArticle, articles } = props

  const topArticles = articles.filter(
    (article) =>
      article.group === currentArticle.group &&
      article.category &&
      article.isPopular
  )

  if (isBlank(topArticles)) {
    return <div style={{ height: 254 }} />
  }

  return (
    <div className="hero">
      <div className="hero-articles">
        {map(topArticles, (article) => (
          <TopArticle key={article.uuid} article={article} />
        ))}
      </div>
    </div>
  )
}

function TopArticle(props) {
  const { article } = props
  const { category } = article

  const tagColor = tagColors[category.toLowerCase()]

  return (
    <div className="hero-article">
      <div>
        {category && (
          <div
            className={varClass({
              'tag': true,
              [`text-${tagColor}`]: !!tagColor,
            })}
          >
            {category}
          </div>
        )}
        <div className="article-title">{article.shortTitle}</div>
      </div>
      <a className="article-link" href={articlePath(article)}>
        Read
      </a>
    </div>
  )
}

function ArticleNotFound(props) {
  return (
    <>
      <div className="breadcrumbs">
        <span>&nbsp;</span>
      </div>
      <article>
        <h1>Article not found</h1>
      </article>
    </>
  )
}

function Sidebar(props) {
  const { article: currentArticle, articles } = props

  const [openCategories, setOpenCategories] = useState(() => [
    currentArticle?.category,
    currentArticle?.subCategory,
  ])
  const toggleOpenCategory = (category) => {
    const newOpenCategories = [...openCategories]
    if (newOpenCategories.includes(category)) {
      newOpenCategories.splice(newOpenCategories.indexOf(category), 1)
    } else {
      newOpenCategories.push(category)
    }
    setOpenCategories(newOpenCategories)
  }

  const categoryMap = useMemo(() => {
    if (!articles) return {}
    const result = {}
    articles
      .filter((article) => {
        if (currentArticle) {
          return article.group === currentArticle.group
        } else {
          return true
        }
      })
      .forEach((article) => {
        result[article.category] = result[article.category] || []
        if (article.subCategory) {
          result[article.category].push(article.subCategory)
          result[article.category] = uniq(result[article.category].sort())
        }
      })
    return result
  }, [articles])

  if (!articles) {
    return null
  }

  const topCategories = Object.keys(categoryMap).sort()

  const categoryArticles = articles
    .filter((article) => {
      if (currentArticle) {
        return article.group === currentArticle.group
      } else {
        return true
      }
    })
    .reduce((result, article) => {
      const categoryKey = getCategoryKey(article)
      result[categoryKey] = result[categoryKey] || []
      result[categoryKey].push(article)
      return result
    }, {})

  return (
    <aside>
      <div className="sticky">
        <a className="h1" href={`/${currentArticle?.group}/get-started`}>
          {articleGroupLabels[currentArticle?.group] ||
            capitalize(currentArticle?.group)}
        </a>
        <ul>
          {map(topCategories, (category) => (
            <li key={category}>
              {!!category ? (
                <>
                  <button
                    onClick={() => toggleOpenCategory(category)}
                    className={varClass({
                      'open': openCategories.includes(category),
                    })}
                  >
                    {category}
                  </button>
                  <ul
                    className={varClass({
                      'open': openCategories.includes(category),
                    })}
                  >
                    {map(
                      categoryArticles[category]?.sort(articleSort),
                      (article) => (
                        <li key={article.uuid}>
                          <a
                            href={articlePath(article)}
                            className={varClass({
                              'active': article.uuid === currentArticle?.uuid,
                            })}
                          >
                            {article.shortTitle}
                          </a>
                        </li>
                      )
                    )}

                    {map(categoryMap[category], (subCategory) => (
                      <li key={subCategory}>
                        <button
                          onClick={() => toggleOpenCategory(subCategory)}
                          className={varClass({
                            'open': openCategories.includes(subCategory),
                          })}
                        >
                          {subCategory}
                        </button>
                        <ul
                          className={varClass({
                            'open': openCategories.includes(subCategory),
                          })}
                        >
                          {map(
                            categoryArticles[
                              `${category}::${subCategory}`
                            ]?.sort(articleSort),
                            (article) => (
                              <li key={article.uuid}>
                                <a
                                  href={articlePath(article)}
                                  className={varClass({
                                    'active':
                                      article.uuid === currentArticle?.uuid,
                                  })}
                                >
                                  {article.shortTitle}
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  {map(
                    categoryArticles[category]?.sort(articleSort),
                    (article) => (
                      <div key={article.uuid}>
                        <a
                          href={articlePath(article)}
                          className={varClass({
                            'active': article.uuid === currentArticle?.uuid,
                          })}
                        >
                          {article.shortTitle}
                        </a>
                      </div>
                    )
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}

function TableOfContent(props) {
  const { article } = props

  const [elements, setElements] = useState([])
  useEffect(() => {
    const headers = document.querySelectorAll('.article h2, .article h3')
    const newElements = []

    for (const index in [...headers]) {
      const header = headers[index]
      const label = header.innerText.trim()
      if (label) {
        const id = 'toc-' + window.btoa(index).replace(/=/g, '')
        header.id = id
        newElements.push({
          id: id,
          label: label,
        })
      }
    }

    setElements(newElements)
  }, [article])

  if (!article || !elements.length) {
    return <div className="content" />
  }

  return (
    <div className="content">
      <div className="sticky">
        <div className="text-bolder m-b-1">On this page</div>
        {map(elements, (element) => (
          <div key={element.id}>
            <a href={`#${element.id}`}>{element.label}</a>
          </div>
        ))}
      </div>
    </div>
  )
}

function Footer(props) {
  const { articles, article: currentArticle } = props

  const sortedArticles = useMemo(() => {
    if (!articles) return []

    const categoryMap = (() => {
      const result = {}
      articles.forEach((article) => {
        result[article.category] = result[article.category] || []
        if (article.subCategory) {
          result[article.category].push(article.subCategory)
          result[article.category] = uniq(result[article.category].sort())
        }
      })
      return result
    })()

    const topCategories = Object.keys(categoryMap).sort()

    const categoryArticles = articles.reduce((result, article) => {
      const categoryKey = getCategoryKey(article)
      result[categoryKey] = result[categoryKey] || []
      result[categoryKey].push(article)
      return result
    }, {})

    const result = []

    topCategories.forEach((category) => {
      if (category) {
        categoryArticles[category]
          ?.sort(articleSort)
          ?.forEach((article) => result.push(article))
        categoryMap[category].forEach((subCategory) => {
          categoryArticles[`${category}::${subCategory}`]
            ?.sort(articleSort)
            ?.forEach((article) => result.push(article))
        })
      } else {
        categoryArticles[category]
          ?.sort(articleSort)
          ?.forEach((article) => result.push(article))
      }
    })

    return result
  }, [articles])

  const articleIndex = sortedArticles.findIndex(
    (article) => article.uuid === currentArticle?.uuid
  )
  const previousArticle = sortedArticles[articleIndex - 1]
  const nextArticle = sortedArticles[articleIndex + 1]

  return (
    <footer>
      <div className="footer-container">
        <div className="row row-space-between">
          {previousArticle ? (
            <a href={articlePath(previousArticle)} className="flex-1">
              <div className="row row-center row-fill text-bold">
                <SVG
                  src="/images/icon-chevron.svg"
                  className="rotate-180 m-r-3"
                />
                Previous page
              </div>
              <div className="text-big text-bolder m-t-2">
                {previousArticle.shortTitle}
              </div>
            </a>
          ) : (
            <div />
          )}

          {nextArticle ? (
            <a href={articlePath(nextArticle)} className="flex-1 text-right">
              <div className="row row-center row-fill row-end text-bold">
                Next page
                <SVG src="/images/icon-chevron.svg" className="m-l-3" />
              </div>
              <div className="text-big text-bolder m-t-2">
                {nextArticle.shortTitle}
              </div>
            </a>
          ) : (
            <div />
          )}
        </div>
      </div>
    </footer>
  )

  //
  //       <div className="footer-container">
  //         <div className="footer-links row row-space-between">
  //           <SVG
  //             src="/images/logo.svg"
  //             width={92}
  //             height={23}
  //             className="m-t-1"
  //           />
  //           <div className="footer-linklist">
  //             <div>Product</div>
  //             <a>Outbound</a>
  //             <a>Inbound</a>
  //             <a>Pricing</a>
  //             <a>Get a demo</a>
  //           </div>
  //           <div className="footer-linklist">
  //             <div>Resources</div>
  //             <a>FAQ</a>
  //             <a>Guides</a>
  //             <a>Documentation</a>
  //           </div>
  //           <div className="footer-linklist">
  //             <div>Legal</div>
  //             <a>Terms of service</a>
  //             <a>Privacy policy</a>
  //             <a>Cookie settings</a>
  //           </div>
  //           <div className="footer-linklist">
  //             <div>Company</div>
  //             <a>Inducement</a>
  //             <a>Newsroom</a>
  //             <a>Career</a>
  //           </div>
  //           <div className="footer-account">
  //             <div>
  //               <a className="btn" href="#">
  //                 Get Started
  //               </a>
  //             </div>
  //             <div>
  //               <a>
  //                 <span>Already got an account</span>
  //               </a>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //
  //       <div className="footer-copyright">
  //         <div className="footer-container row row-center row-space-between h-100">
  //           <span className="text-light text-smaller text-bold">
  //             2022 © Heylink.com - All right reserved
  //           </span>
  //           <a href="/sitemap.xml" className="link">
  //             Sitemap
  //           </a>
  //         </div>
  //       </div>
  //     </footer>
  //   )
  // }
  //
  // function TestArticle(props) {
  //   return (
  //     <>
  //       <h1>How to create a session with no code</h1>
  //       <p>
  //         After you have installed Heylinks’ API on your main website (your own
  //         digital platform) and you have logged into the Heylink dashboard and see
  //         the API is active and fully running on your website
  //       </p>
  //       <p>Then we can proceed In this guide, you’ll learn how to:</p>
  //       <ol>
  //         <li>
  //           Receive an event notification when whenever someone has used your link
  //         </li>
  //         <li>How to meassure which links are useful or not</li>
  //         <li>Use Heylink CLI to quickly test your new event handler</li>
  //         <li>Turn on your event handler in production</li>
  //       </ol>
  //       <hr />
  //       <h2>Install Heylink CLI</h2>
  //       <p>
  //         The quickest way to develop and test webhooks locally is with the Stripe
  //         CLI. As a first step, follow the install guide for Stripe CLI. After you
  //         finish the install guide, run the following command to test that your
  //         Stripe CLI installation works:
  //       </p>
  //       <code>
  //         heylink status
  //         <br />
  //         <br />
  //         All services online.
  //       </code>
  //       <p>
  //         If you see a success message after running stripe status, you’re ready
  //         to move on to the next step.
  //       </p>
  //     </>
  //   )
}
