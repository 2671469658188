import React from 'react'
import { Layout } from '../components/layout'
import { ArticlePage } from '../components/article'

const ArticlePageContainer = (props) => {
  return (
    <Layout>
      <ArticlePage {...props} />
    </Layout>
  )
}

export default ArticlePageContainer
